import { getUser } from 'api/media.api';
import useSWR from 'swr';

/**
 * Hook that gets the user for a PSMedia object
 */
export function useMediaUser(media: PSMedia | null) {
    const { data, error } = useSWR(
        media ? `/media_user/${media.id}` : null,
        () => {
            if (media) {
                return getUser(media);
            }
            return undefined;
        },
    );
    return {
        user: data,
        error,
        isLoading: !data && !error,
    };
}

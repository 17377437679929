import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Typography, Alert, Button, Table, Pagination, Card } from 'antd';
import Loading from 'components/Loading';
import { getDataSourceFromArray } from 'utils/data-source-utils';
import { usePaginatedGet } from 'utils/use-paginated-get';
import type { ColumnsType, Key, SortOrder } from 'antd/lib/table/interface';

import './style.css';

const { Text } = Typography;

interface ApiGetterResult<T> {
    pagination: PaginationInfo;
    results: T[];
}

interface GenericOverviewTableProps<T> {
    className?: string;
    /** Function to call to call the api */
    apiGetter: (pageNumber: number) => Promise<ApiGetterResult<T>>;
    columns: ColumnsType<T>;
    emptyTableMessage?: string;
    sortCallback: (
        column: Key | undefined,
        order: SortOrder | null | undefined,
    ) => void;
    /** This key can be used to force a refresh of the table, if the key changes the table will be refreshed */
    key?: string | number;
}

export function GenericOverviewTable<T extends object>({
    className,
    apiGetter,
    columns,
    emptyTableMessage = 'No values yet.',
    sortCallback,
    key,
}: GenericOverviewTableProps<T>) {
    const {
        isLoading,
        errorLoadingMessage,
        currentPageValues,
        currentPage,
        setCurrentPage,
        lastPaginationInfo,
        refresh,
    } = usePaginatedGet(apiGetter);

    useEffect(() => {
        if (key) {
            refresh();
        }
    }, [key, refresh]);

    return (
        <>
            {isLoading && (
                <div className="full-page-content-center">
                    <Loading tip="Loading..." />
                </div>
            )}
            {errorLoadingMessage && (
                <div className="full-page-content-center full-page-error">
                    <Alert
                        message={errorLoadingMessage}
                        description={errorLoadingMessage}
                        type="error"
                        showIcon
                    />
                    <div className="full-page-error-button">
                        <Button type="primary" onClick={() => refresh()}>
                            Try loading again
                        </Button>
                    </div>
                </div>
            )}

            {currentPageValues?.length === 0 && (
                <div className="full-page-content-center">
                    <Text type="secondary">{emptyTableMessage}</Text>
                </div>
            )}

            {currentPageValues &&
                currentPageValues?.length > 0 &&
                lastPaginationInfo && (
                    <Card
                        bodyStyle={{ padding: '24px 0' }}
                        bordered={false}
                        className="mb-m"
                    >
                        {getPagination(
                            {
                                ...lastPaginationInfo,
                                page: currentPage,
                            },
                            setCurrentPage,
                        )}
                        <Table
                            showSorterTooltip
                            className={classNames('mt-m mb-m', className)}
                            dataSource={getDataSourceFromArray(
                                currentPageValues,
                            )}
                            columns={columns}
                            pagination={false}
                            onChange={(_pagination, _filters, sorter) => {
                                if (Array.isArray(sorter)) {
                                    // we don't handle this
                                    return;
                                }
                                sortCallback(sorter.columnKey, sorter.order);
                                columns.map((c) => {
                                    if (c.key === sorter.columnKey) {
                                        c.sortOrder = sorter.order;
                                    } else {
                                        c.sortOrder = null;
                                    }
                                });
                            }}
                        />
                        {getPagination(
                            {
                                ...lastPaginationInfo,
                                page: currentPage,
                            },
                            setCurrentPage,
                        )}
                    </Card>
                )}
        </>
    );
}

function getPagination(
    paginationInfo: PaginationInfo,
    setCurrentPage: (newPage: number) => void,
) {
    if (!paginationInfo?.totalPages || paginationInfo.totalPages < 2) {
        return null;
    }

    return (
        <div className="line-center mr-m">
            <div className="grow-full-flex" />
            <Pagination
                current={paginationInfo.page}
                onChange={setCurrentPage}
                total={paginationInfo.totalResults}
                pageSize={paginationInfo.resultsPerPage}
                showSizeChanger={false}
            />
        </div>
    );
}

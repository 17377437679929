import GuidesOverview from 'pages/GuidesOverview';
import CollectionTypes from 'pages/CollectionTypes';
import AddCollectionType from 'pages/AddCollectionType';
import EditCollectionType from 'pages/EditCollectionType';
import CollectionsOverview from 'pages/CollectionsOverview';
import AddGuide from 'pages/AddGuide';
import EditGuide from 'pages/EditGuide';
import EditPolarstepsSpot from 'pages/EditPolarstepsSpot';
import { Destinations } from 'pages/Destinations';
import { AddDestination } from 'pages/AddDestination';
import { EditDestination } from 'pages/EditDestination';
import { MediaDiscovery } from 'pages/MediaDiscovery';

const DEFAULTS = {
    disabled: false,
    exact: true,
};

export interface RouteItem {
    title: string;
    id: string;
    /** relative (to root) path of this route */
    route: string;
    /** React component to show for this route */
    component?: () => JSX.Element;
    disabled?: boolean;
    /** When true, will only match if the path matches the location.pathname exactly. (https://reacttraining.com/react-router/web/api/Route/exact-bool) */
    exact?: boolean;
    /** When true this will be the default route to use */
    default?: boolean;
}

export const routes: Record<string, RouteItem> = {
    home: {
        ...DEFAULTS,
        title: 'Home',
        id: 'home',
        route: '/',
        disabled: true,
    },
    users: {
        ...DEFAULTS,
        title: 'Users',
        id: 'users',
        route: '/users',
        disabled: true,
    },
    trips: {
        ...DEFAULTS,
        title: 'Trips',
        id: 'trips',
        route: '/trips',
        disabled: true,
    },
    'travel-books': {
        ...DEFAULTS,
        title: 'Travel books',
        id: 'travel-books',
        route: '/travel-books',
        disabled: true,
    },
    explore: {
        ...DEFAULTS,
        title: 'Explore',
        id: 'explore',
        route: '/explore',
        disabled: true,
    },
    'guides-overview': {
        ...DEFAULTS,
        title: 'Overview',
        id: 'guides-overview',
        route: '/guides',
        component: GuidesOverview,
        default: true,
    },
    'add-guide': {
        ...DEFAULTS,
        title: 'Add a guide',
        id: 'add-guide',
        route: '/guides/add',
        component: AddGuide,
    },
    'edit-guide': {
        ...DEFAULTS,
        title: 'Edit a guide',
        id: 'edit-guide',
        route: '/guides/guide/:id',
        component: EditGuide,
    },
    'guides-collections': {
        ...DEFAULTS,
        title: 'Collections',
        id: 'guides-collections',
        route: '/guides/collections',
        component: CollectionsOverview,
    },
    'guides-collection-types': {
        ...DEFAULTS,
        title: 'Collection types',
        id: 'guides-collection-types',
        route: '/guides/collection-types',
        component: CollectionTypes,
    },
    'add-collection-type': {
        ...DEFAULTS,
        title: 'Add collection type',
        id: 'add-collection-type',
        route: '/guides/collection-types/add',
        component: AddCollectionType,
    },
    'edit-collection-type': {
        ...DEFAULTS,
        title: 'Edit collection type',
        id: 'edit-collection-type',
        route: '/guides/collection-type/:id',
        component: EditCollectionType,
    },
    'edit-polarsteps-spot': {
        ...DEFAULTS,
        title: 'Edit Polarsteps spot type',
        id: 'edit-polarsteps-spot',
        route: '/polarsteps-spot/:id',
        component: EditPolarstepsSpot,
    },
    advanced: {
        ...DEFAULTS,
        title: 'Advanced',
        id: 'advanced',
        route: '/advanced',
        disabled: true,
    },
    destinations: {
        ...DEFAULTS,
        title: 'Destinations',
        id: 'destinations',
        route: '/destinations',
        disabled: false,
        component: Destinations,
    },
    'add-destination': {
        ...DEFAULTS,
        title: 'Add destination',
        id: 'add-destination',
        route: '/destinations/add',
        component: AddDestination,
    },
    'edit-destination': {
        ...DEFAULTS,
        title: 'Edit destination',
        id: 'edit-destination',
        route: '/destinations/:id',
        component: EditDestination,
    },
    'media-discovery': {
        ...DEFAULTS,
        title: 'Media discovery',
        id: 'media-discovery',
        route: '/media-discovery',
        component: MediaDiscovery,
    },
};

/**
 * Gets the route that is active given a path
 */
export function getActiveRoute(
    currentPath: string,
    routes: Record<string, RouteItem>,
): RouteItem | undefined {
    return Object.values(routes).find((route) => route.route === currentPath);
}

import {
    EnvironmentOutlined,
    SaveOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import {
    Alert,
    Button,
    Card,
    notification,
    Radio,
    Tooltip,
    Typography,
} from 'antd';
import { useAllCollectionSpotsFromGuide } from 'api/collection-spots.api';
import CollectionTypeSelector from 'components/CollectionTypeSelector';
import Loading from 'components/Loading';
import React, { Suspense, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { patchGuideSingleField } from 'store/guide-editor/actions';
import { getCurrentGuide } from 'store/guide-editor/selectors';
import { parse } from 'utils/error-parser';
import useAllPublicStepSpots from 'utils/use-all-public-step-spots';

import {
    MapHighlightedAreaTypes,
    MapSpotFilterTypes,
} from 'constants/collection-map';
import './style.css';

const CMSMap = React.lazy(() => import('components/CMSMap/CMSMap'));
const { Text, Title } = Typography;

/**
 *
 * @param {Id} collectionId
 * @param {Id} [filterCollectionId]
 */
const filterByCollection = (collectionId, filterCollectionId) =>
    !filterCollectionId || collectionId === filterCollectionId;

const contentByType = {
    interest: {
        icon: <EnvironmentOutlined />,
        title: 'Area of Interest',
        description: (
            <>
                <Text type="secondary">
                    By editing the area of interest, you control which part of
                    the map is visible in the Guide overview page in the
                    Polarsteps app.
                </Text>
                <div className="mb-xs"></div>
                <Text type="secondary">
                    You can position the map and adjust the blue area. The map
                    in the app will automatically adjust to include all the
                    spots you&apos;ve &quot;covered&quot; with the blue area.
                </Text>
            </>
        ),
    },
    search: {
        icon: <SearchOutlined />,
        title: 'Search Area',
        description: (
            <Text type="secondary">
                If users place a Planned Step in the area that is covered by the
                Search Area, they will see there is a Guide for this area. You
                should therefore set this area to cover the full city or region
                that your Guide covers.
            </Text>
        ),
    },
};

/**
 *
 * @param {Object} props
 * @param {HighlightedAreaType} props.type
 * @param {React.Dispatch<HighlightedAreaType>} props.onClick
 */
const AreaButton = ({ type, onClick }) => (
    <Tooltip
        placement="leftBottom"
        title={
            <div className="guide-editor-map__tooltip">
                {contentByType[type].description}
            </div>
        }
    >
        <Button size="large" onClick={() => onClick(type)}>
            <div className="guide-editor-map__button-content">
                <div className="guide-editor-map__button-label">
                    {contentByType[type].icon}
                    {`Edit ${contentByType[type].title}`}
                </div>
                <div className="guide-editor-map__legend-container">
                    <div
                        className={`guide-editor-map__area-legend guide-editor-map__area-legend--${type}`}
                    >
                        <div />
                    </div>
                </div>
            </div>
        </Button>
    </Tooltip>
);

function GuideEditorMap() {
    const dispatch = useDispatch();
    const guide = useSelector(getCurrentGuide);
    /** @type {[HighlightedAreaType, React.Dispatch<React.SetStateAction<HighlightedAreaType>>]} */
    const [isEditingArea, setIsEditingArea] = useState(null);
    /** @type {[GeoJSON.Polygon, React.Dispatch<React.SetStateAction<GeoJSON.Polygon>>]} */
    const [newArea, setNewArea] = useState(null);
    /** @type {[MapSpotFilterType, React.Dispatch<React.SetStateAction<MapSpotFilterType>>]} */
    const [typeFilter, setTypeFilter] = useState(null);
    /** @type {[Id | undefined, React.Dispatch<React.SetStateAction<Id | undefined>>]} */
    const [collectionFilter, setCollectionFilter] = useState();
    const [isSavingArea, setIsSavingArea] = useState(false);
    const { spots = [] } = useAllCollectionSpotsFromGuide(guide?.id);
    const { stepSpots = [] } = useAllPublicStepSpots(guide?.id, false);
    const viewportArea = guide?.viewport_area;
    const searchArea = guide?.search_area;

    if (!guide) {
        return null;
    }

    const collectionSpots =
        typeFilter !== MapSpotFilterTypes.TIPS
            ? spots.filter(({ collection }) =>
                  filterByCollection(
                      collection.collection_type_id,
                      collectionFilter,
                  ),
              )
            : [];
    const tipSpots =
        typeFilter !== MapSpotFilterTypes.SPOTS
            ? stepSpots.filter(([_, steps]) =>
                  filterByCollection(steps[0].spot.id, collectionFilter),
              )
            : [];

    const onSaveArea = async () => {
        setIsSavingArea(true);
        try {
            await dispatch(
                patchGuideSingleField(
                    isEditingArea === MapHighlightedAreaTypes.INTEREST
                        ? 'viewport_area'
                        : 'search_area',
                    JSON.stringify(newArea),
                ),
            );
            setIsEditingArea(null);
            setNewArea(null);
        } catch (e) {
            notification.error({
                message: 'Error saving changes.',
                description: parse(e),
            });
        }
        setIsSavingArea(false);
    };

    const noBBMessage =
        "Guide still doesn't have a bounding box for the map. Please reselect the location to automatically generate it.";

    return (
        <>
            <div className="guide-editor-map__title">
                <Title level={4}>Map</Title>
                <div className="guide-editor-map__filter">
                    <Radio.Group
                        defaultValue={typeFilter}
                        buttonStyle="solid"
                        onChange={(e) => setTypeFilter(e.target.value)}
                    >
                        <Radio.Button value={null}>All</Radio.Button>
                        <Radio.Button value={MapSpotFilterTypes.SPOTS}>
                            Spots
                        </Radio.Button>
                        <Radio.Button value={MapSpotFilterTypes.TIPS}>
                            Tips
                        </Radio.Button>
                    </Radio.Group>
                    <div className="guide-editor-map__collection-filter">
                        <CollectionTypeSelector
                            value={collectionFilter}
                            onChange={(newTypeId) => {
                                setCollectionFilter(newTypeId);
                            }}
                            placeholder="Filter by collection type"
                            size="middle"
                            allowClear
                        ></CollectionTypeSelector>
                    </div>
                </div>
            </div>
            {viewportArea || searchArea ? (
                <div className="guide-editor-map__map-container">
                    <Suspense fallback={<Loading />}>
                        <CMSMap
                            isEditingArea={isEditingArea}
                            highlightedAreaOfInterest={viewportArea}
                            highlightedSearchArea={searchArea}
                            onAreaChanged={setNewArea}
                            collectionSpots={collectionSpots}
                            tipSpots={tipSpots}
                        />
                    </Suspense>
                    <div className="guide-editor-map__actions">
                        {isEditingArea ? (
                            <div className="line">
                                <Button
                                    size="small"
                                    type="primary"
                                    loading={isSavingArea}
                                    icon={<SaveOutlined />}
                                    onClick={onSaveArea}
                                >
                                    Save changes
                                </Button>
                                <Button
                                    className="ml-m"
                                    size="small"
                                    disabled={isSavingArea}
                                    onClick={() => {
                                        setIsEditingArea(null);
                                        setNewArea(null);
                                    }}
                                >
                                    Discard changes
                                </Button>
                            </div>
                        ) : (
                            <>
                                <AreaButton
                                    type={MapHighlightedAreaTypes.INTEREST}
                                    onClick={setIsEditingArea}
                                />
                                <AreaButton
                                    type={MapHighlightedAreaTypes.SEARCH}
                                    onClick={setIsEditingArea}
                                />
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <Card>
                    <Alert message={noBBMessage} showIcon type="warning" />
                </Card>
            )}
        </>
    );
}

export default GuideEditorMap;

import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import { Spin, Button, notification, Modal } from 'antd';
import {
    CheckCircleTwoTone,
    HeartFilled,
    HeartOutlined,
} from '@ant-design/icons';
import { useMediaFavorite } from './useMediaFavorite';
import { useCurrentUser } from 'utils/use-current-user';
const { confirm } = Modal;

/**
 *
 * @param {Object} props
 * @param {ShutterstockPhotoFromSearch | UnsplashPhotoFromSearch | PSSpot_v15_Photo | PSMedia} props.media
 * @param {Id} [props.guideId]
 * @param {() => void} props.onSelect
 * @param {boolean} props.isSelected
 * @param {MediaSelectorMediaType} props.mediaType
 * @param {string} props.imgSrc
 * @param {PSSpot_v15_Minimal} [props.spot] Only needed if we want to add a spot media item to favorites
 * @param {boolean} [props.isLoading=false]
 * @param {boolean} [props.canBeFavorite=true]
 * @param {React.ReactNode} [props.children]
 * @param {React.ReactNode} [props.extraContent]
 */
export default function MediaItemContent({
    media,
    guideId,
    onSelect,
    isSelected,
    mediaType,
    isLoading = false,
    canBeFavorite = true,
    spot,
    imgSrc,
    extraContent,
    children,
}) {
    /** @type {FavoriteImageData} */
    // @ts-ignore
    const mediaForFavorite = useMemo(() => {
        if (mediaType === 'Spot') {
            return {
                spot,
                photo: media,
            };
        }
        return media;
    }, [mediaType, media, spot]);
    const {
        isFavorite,
        addFavorite,
        removeFavorite,
        relatedFavorite,
    } = useMediaFavorite(mediaForFavorite, mediaType, guideId);
    const [isUpdatingFav, setIsUpdatingFav] = useState(false);
    const currentUser = useCurrentUser();

    const removeFavoriteAskingConfirmIfNeeded = async () => {
        if (currentUser?.id !== relatedFavorite?.creator_id) {
            const canRemove = await new Promise((resolve) => {
                confirm({
                    title: "Removing other editor's favorite!",
                    content:
                        'You are about to delete a favorite other editor added, are you sure?',
                    onOk() {
                        resolve(true);
                    },
                    onCancel() {
                        resolve(false);
                    },
                    okText: 'Remove it!',
                    cancelText: 'Cancel',
                });
            });
            if (!canRemove) {
                return;
            }
        }
        return removeFavorite();
    };

    return (
        <div
            className={classNames('media-selector-image-list-item', {
                'is-selected': isSelected,
            })}
            onClick={onSelect}
        >
            <img src={imgSrc} loading="lazy" alt="" />

            <CheckCircleTwoTone
                style={{ fontSize: 22 }}
                className="media-selector-image-list-item-selected"
            />

            {isLoading && (
                <div className="media-selector-image-list-item-loading">
                    <Spin />
                </div>
            )}
            {children}
            {canBeFavorite && (
                <div className="media-selector-image-list-item-favorite">
                    <Button
                        loading={isUpdatingFav}
                        icon={
                            isFavorite ? (
                                <HeartFilled
                                    className="media-selector-image-list-item-favorite-icon"
                                    style={{ color: '#e34646' }}
                                />
                            ) : (
                                <HeartOutlined className="media-selector-image-list-item-favorite-icon" />
                            )
                        }
                        onClick={async (e) => {
                            e.stopPropagation();
                            setIsUpdatingFav(true);
                            try {
                                if (isFavorite) {
                                    await removeFavoriteAskingConfirmIfNeeded();
                                } else {
                                    await addFavorite();
                                }
                            } catch (e) {
                                notification.error({
                                    message: 'Error adding/removing favorite',
                                });
                            }
                            setIsUpdatingFav(false);
                        }}
                    />
                </div>
            )}
            {extraContent && (
                <div className="media-selector-image-list-item-extra-content">
                    {extraContent}
                </div>
            )}
        </div>
    );
}

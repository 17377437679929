import React, { Suspense, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { Polygon } from 'geojson';
import styles from './DestintionAreaEditor.module.css';

const CMSMap = React.lazy(() => import('components/CMSMap/CMSMap'));

interface DestinationAreaEditorProps {
    value: Polygon;
    onChange: (value: Polygon) => void;
}

export function DestinationAreaEditor({
    value,
    onChange,
}: DestinationAreaEditorProps) {
    const [editedArea, setEditedArea] = useState<Polygon | null>(null);
    const [isEditingArea, setIsEditingArea] = useState(false);

    const startEditingArea = () => {
        if (value) {
            setEditedArea(value);
            setIsEditingArea(true);
        }
    };

    const saveArea = () => {
        setIsEditingArea(false);
        if (editedArea) {
            onChange(editedArea);
        }
    };
    return (
        <>
            <div className={styles.map__editButton}>
                {isEditingArea ? (
                    <Tooltip
                        title="You need to save the area before you can save the destination"
                        open
                    >
                        <Button onClick={saveArea} type="primary">
                            Save new area
                        </Button>
                    </Tooltip>
                ) : (
                    <Button onClick={startEditingArea}>Edit area</Button>
                )}
            </div>
            <Suspense>
                <CMSMap
                    className={styles.map__inner}
                    isEditingArea={isEditingArea ? 'interest' : null}
                    highlightedAreaOfInterest={value}
                    highlightedSearchArea={value}
                    collectionSpots={[]}
                    tipSpots={[]}
                    onAreaChanged={setEditedArea}
                />
            </Suspense>
        </>
    );
}

import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { Sidebar } from 'components/Sidebar';
import { ConfigProvider, Layout } from 'antd';
import * as Sentry from '@sentry/react';
import { routes } from 'routes';
import AuthLayer from 'components/AuthLayer';
import BeforeUnloadWarning from 'components/BeforeUnloadWarning';
import ErrorBoundaryMessage from 'components/ErrorBoundary/ErrorBoundaryMessage.jsx';
import { FeatureFlagsContextComponent } from 'feature-flags.jsx';
import './App.css';

const { Content } = Layout;

function App() {
    const routesToShow = Object.values(routes).filter(
        (route) => !route.disabled,
    );
    const defaultRoute = routesToShow.find((route) => route.default);

    return (
        // @ts-ignore
        <Sentry.ErrorBoundary
            fallback={({ error, componentStack }) => (
                <ErrorBoundaryMessage
                    error={error.toString()}
                    stacktrace={componentStack}
                />
            )}
        >
            <FeatureFlagsContextComponent>
                <Provider store={store}>
                    <AuthLayer>
                        <Router>
                            <BeforeUnloadWarning />
                            <ConfigProvider
                                theme={{
                                    components: { Modal: { wireframe: true } },
                                }}
                            >
                                <Layout style={{ minHeight: '100vh' }}>
                                    <Sidebar />
                                    <Layout>
                                        <Content>
                                            <Switch>
                                                {routesToShow.map((route) => (
                                                    <Route
                                                        path={route.route}
                                                        key={route.id}
                                                        exact
                                                    >
                                                        {getRouteComponent(
                                                            route,
                                                        )}
                                                    </Route>
                                                ))}
                                                {defaultRoute && (
                                                    <Route path="/" exact>
                                                        <Redirect
                                                            to={
                                                                defaultRoute.route
                                                            }
                                                        />
                                                    </Route>
                                                )}
                                            </Switch>
                                        </Content>
                                    </Layout>
                                </Layout>
                            </ConfigProvider>
                        </Router>
                    </AuthLayer>
                </Provider>
            </FeatureFlagsContextComponent>
        </Sentry.ErrorBoundary>
    );
}

function getRouteComponent(route) {
    if (!route.component) {
        return <>TODO: {route.title}</>;
    }
    const RouteComponent = route.component;
    return <RouteComponent />;
}

export default App;

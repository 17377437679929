import useSWR from 'swr';
import { get, patch, post } from 'utils/http';
import { BASE_URL } from 'constants/env';

const defaultOptions = {
    headers: {
        'Polarsteps-Api-Version': '58',
    },
};

export async function getAllDestinations(
    filter: string,
    sortColumn: string | undefined,
    sortOrder: string | undefined,
    page: number,
) {
    const resultsPerPage = 20;
    const params = new URLSearchParams({
        page: `${page}`,
        results_per_page: `${resultsPerPage}`,
    });

    if (filter.length) {
        params.set('q', filter);
    }

    if (sortColumn) {
        params.set('sort_column', sortColumn);
    }
    if (sortOrder) {
        params.set('sort_order', sortOrder);
    }

    const allDestinations = (await get(
        `${BASE_URL}/cms/destination_media?${params.toString()}`,
        defaultOptions,
    )) as {
        page: number;
        total_pages: number;
        num_results: number;
        objects: Destination[];
    };

    return {
        pagination: {
            page: allDestinations.page,
            totalPages: allDestinations.total_pages,
            resultsPerPage,
            totalResults: allDestinations.num_results,
        },
        results: allDestinations.objects,
    };
}

export function useDestination(destinationId: string | undefined) {
    const { data, isValidating, error } = useSWR(
        destinationId ? `destination_by_id/${destinationId}` : null,
        () => {
            if (destinationId) {
                return getDestination(destinationId);
            }
            return undefined;
        },
    );
    return { destination: data, isLoading: isValidating && !data, error };
}

async function getDestination(destinationId: string): Promise<Destination> {
    return get(
        `${BASE_URL}/cms/destination_media/${destinationId}`,
        defaultOptions,
    );
}

export function addDestination(destination: DestinationToAdd) {
    return post(
        `${BASE_URL}/cms/destination_media`,
        destination,
        defaultOptions,
    );
}

export function patchDestination(destination: DestinationToPatch) {
    return patch(
        `${BASE_URL}/cms/destination_media/${destination.id}`,
        destination,
        defaultOptions,
    );
}

import React from 'react';
import UploadedPhotoMediaItem from './UploadedPhotoMediaItem';
// import { convertPSMediaToCMSMedia, isPSMediaSelected } from 'utils/media-utils';

/**
 *
 * @param {Object} props
 * @param {CMSMedia?} props.selectedMedia
 * @param {Id} [props.guideId]
 * @param {(media: CMSMedia) => void} props.onSelect
 * @param {boolean} props.isShown
 * @param {React.ReactNode} props.sourceSelector
 * @param {CollectionSpot} props.collectionSpot
 * @returns {JSX.Element}
 */
export default function UploadedPhotosMedia({
    selectedMedia,
    guideId,
    onSelect,
    isShown,
    sourceSelector,
    collectionSpot,
}) {
    /**
     *
     * @param {CMSMedia} media
     */
    const onMediaSelected = (media) => {
        onSelect({
            ...media,
            external_id: media.id + '', // External id is the same as the original id, so we know where it came from before cropping.
        });
    };

    return (
        <div style={{ display: isShown ? 'block' : 'none' }}>
            <div className="media-selector__tools">
                {sourceSelector}
                <div className="grow-full-flex" />
            </div>

            <div className="media-selector-image-list-container">
                <div className="media-selector-image-list">
                    {collectionSpot.collection_spot_media.map((item) => (
                        <UploadedPhotoMediaItem
                            key={item.id}
                            media={item.cms_media}
                            guideId={guideId}
                            onSelect={() => onMediaSelected(item.cms_media)}
                            isSelected={item.cms_media_id === selectedMedia?.id}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

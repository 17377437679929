import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from '@react-hook/debounce';
import { GenericOverviewTable } from 'components/GenericOverviewTable';
import { Button, Input } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import { PlusOutlined } from '@ant-design/icons';
import { getAllGuides } from 'api/guides.api';
import PublishStatusTag from 'components/PublishStatusTag';
import './style.css';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';

const { Search } = Input;

function GuidesOverview() {
    const [filter, setFilter] = useDebounce('', 300);
    const [columns] = useState([
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, guide) => (
                <Link to={`/guides/guide/${guide.id}`}>{guide.name}</Link>
            ),
            sorter: true,
            sortOrder: null,
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            render: (text, guide) => guide.country,
            sorter: true,
            sortOrder: null,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, guide) => (
                <PublishStatusTag publishStatus={guide.publish_status} />
            ),
            sorter: true,
            sortOrder: null,
        },
        {
            title: 'First Published',
            dataIndex: 'published_at',
            key: 'published_at',
            render: (text, guide) =>
                guide.published_at &&
                format(parseISO(guide.published_at), 'MMM d, y'),
            sorter: true,
            sortOrder: null,
        },
        {
            title: 'Last Reviewed',
            dataIndex: 'reviewed_at',
            key: 'reviewed_at',
            render: (text, guide) =>
                guide.reviewed_at &&
                format(parseISO(guide.reviewed_at), 'MMM d, y'),
            sorter: true,
            sortOrder: null,
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (text, guide) => (
                <a
                    href={`https://polarsteps.com/guides/${guide.id}`}
                    target="_BLANK"
                    rel="noreferrer"
                >
                    View
                </a>
            ),
            sorter: false,
            sortOrder: null,
        },
    ]);
    const [sortColumn, setSortColumn] = useState();
    const [sortOrder, setSortOrder] = useState();
    const apiGetter = useCallback(
        (pageNumber) => getAllGuides(filter, sortColumn, sortOrder, pageNumber),
        [filter, sortColumn, sortOrder],
    );

    const sortCallback = (column, order) => {
        setSortColumn(column);
        setSortOrder(order);
    };

    return (
        <div className="full-page">
            <PageHeader
                ghost={false}
                title="Guides overview"
                extra={
                    <Link
                        to="/guides/add"
                        className="full-page-header-tools-add-button"
                    >
                        <Button
                            type="primary"
                            size="large"
                            icon={<PlusOutlined />}
                        >
                            Add new guide
                        </Button>
                    </Link>
                }
            >
                <Search
                    className="full-page-header-tools-filter"
                    placeholder="Search for guides"
                    size="large"
                    onChange={(e) => setFilter(e.target.value)}
                />
            </PageHeader>

            <div className="full-page-content">
                <div className="max-content-width mb-m">
                    <GenericOverviewTable
                        columns={columns}
                        apiGetter={apiGetter}
                        emptyTableMessage={
                            filter
                                ? 'No guides found with this text.'
                                : 'Sorry no guides yet.'
                        }
                        sortCallback={sortCallback}
                    ></GenericOverviewTable>
                </div>
            </div>
        </div>
    );
}

export default GuidesOverview;

import React from 'react';
import { Select } from 'antd';
import { useFeatureFlag } from 'feature-flags';

export type MediaSource =
    | 'POLARSTEPS'
    | 'FAVORITES'
    // Depending on the spot source we will use Airbnb, 4square, GetYourGuide, etc.
    | 'SPOT'
    | 'UNSPLASH'
    | 'SHUTTERSTOCK'
    | 'UPLOADED_PHOTOS';

/**
 * Checks if a spot source provides photos.
 */
export function isSpotSourceWithPhotos(source: string) {
    if (!source) {
        return false;
    }
    const SPOT_SOURCES_WITHOUT_PHOTOS = ['polarsteps_spots'];
    return !SPOT_SOURCES_WITHOUT_PHOTOS.includes(source);
}

export interface MediaSelectorSourceSelectorProps {
    spot: PSSpot_v15 | undefined;
    onChange: (value: MediaSource) => void;
    value: MediaSource;
    disallowedSources: MediaSource[];
}

export function MediaSelectorSourceSelector({
    spot,
    onChange,
    value,
    disallowedSources,
}: MediaSelectorSourceSelectorProps) {
    const [ENABLE_UNSPLASH] = useFeatureFlag('ENABLE_UNSPLASH');
    const [ENABLE_SHUTTERSTOCK] = useFeatureFlag('ENABLE_SHUTTERSTOCK');

    return (
        <Select onChange={onChange} value={value} style={{ minWidth: 156 }}>
            {!disallowedSources.includes('POLARSTEPS') ? (
                <Select.Option key="POLARSTEPS" value="POLARSTEPS">
                    Polarsteps
                </Select.Option>
            ) : null}
            {ENABLE_SHUTTERSTOCK &&
            !disallowedSources.includes('SHUTTERSTOCK') ? (
                <Select.Option key="SHUTTERSTOCK" value="SHUTTERSTOCK">
                    Shutterstock
                </Select.Option>
            ) : null}
            {ENABLE_UNSPLASH && !disallowedSources.includes('UNSPLASH') ? (
                <Select.Option key="UNSPLASH" value="UNSPLASH">
                    Unsplash
                </Select.Option>
            ) : null}
            {!disallowedSources.includes('FAVORITES') ? (
                <Select.Option key="FAVORITES" value="FAVORITES">
                    Favorites
                </Select.Option>
            ) : null}
            {spot &&
            isSpotSourceWithPhotos(spot.source) &&
            !disallowedSources.includes('SPOT') ? (
                <Select.Option key="SPOT" value="SPOT">
                    {spot.source}
                </Select.Option>
            ) : null}
            {spot && !disallowedSources.includes('UPLOADED_PHOTOS') ? (
                <Select.Option key="UPLOADED_PHOTOS" value="UPLOADED_PHOTOS">
                    Uploaded photos
                </Select.Option>
            ) : null}
        </Select>
    );
}

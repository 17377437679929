import React, { useCallback, useMemo, useState } from 'react';
import styles from './style.module.css';
import { PageHeader } from '@ant-design/pro-layout';
import LocationSelector from 'components/LocationSelector';
import InfiniteScroll from 'react-infinite-scroller';
import { useInfiniteScrollGet } from 'utils/use-infinite-scroll-get';
import { searchFromLocation } from 'api/media.api';
import { VIDEO, IMAGE } from 'constants/media-types.constants';
import { Radio, Spin } from 'antd';
import { MediaDiscoveryItem } from './MediaDiscoveryItem';

export function MediaDiscovery() {
    const [location, setLocation] = useState<PSLocationWithBB>();
    const [mediaType, setMediaType] = useState<'video' | 'photo'>('video');

    const allowedMediaTypes = useMemo(
        () => (mediaType === 'video' ? [VIDEO] : [IMAGE]),
        [mediaType],
    );

    const apiGetter = useCallback(
        (page: number) => searchFromLocation(location, allowedMediaTypes, page),
        [location, allowedMediaTypes],
    );

    const {
        isLoading,
        getNewPage,
        hasMoreResults,
        results,
    } = useInfiniteScrollGet<PSMedia>(apiGetter);

    const noMediaFound = !isLoading && !hasMoreResults && !results.length;

    return (
        <div className="full-page">
            <PageHeader ghost={false} title="Media discovery">
                <div className={styles.searchTools}>
                    <LocationSelector
                        placeholder="Search content in a location"
                        value={location ?? null}
                        onChange={(newLocation) => {
                            setLocation(newLocation);
                        }}
                    />
                    <Radio.Group
                        buttonStyle="outline"
                        value={mediaType}
                        onChange={(e) => {
                            setMediaType(e.target.value);
                        }}
                    >
                        <Radio.Button value={'video'}>Videos</Radio.Button>
                        <Radio.Button value={'photo'}>Photos</Radio.Button>
                    </Radio.Group>
                </div>
            </PageHeader>
            <div className="full-page-content">
                <div className="max-content-width mb-m">
                    {location ? (
                        <>
                            {noMediaFound && (
                                <div className={styles.noResults}>
                                    No media found
                                </div>
                            )}
                            <InfiniteScroll
                                key={`${location?.id}__${allowedMediaTypes.join(
                                    '_',
                                )}`}
                                pageStart={-1}
                                loadMore={getNewPage}
                                threshold={400}
                                hasMore={!isLoading && hasMoreResults}
                                useWindow={false}
                            >
                                <ul className={styles.mediaList}>
                                    {results.map((item) => (
                                        <li key={item.id}>
                                            <MediaDiscoveryItem media={item} />
                                        </li>
                                    ))}
                                </ul>
                                {isLoading ? (
                                    <div className={styles.loading}>
                                        <Spin />
                                    </div>
                                ) : null}
                            </InfiniteScroll>
                        </>
                    ) : (
                        <div className={styles.noResults}>
                            Please select a location to see media related to it
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

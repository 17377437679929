import React, { useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, notification } from 'antd';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { DestinationEditor } from 'components/DestinationEditor';
import { isDestinationCorrect } from 'utils/destination-utils';
import { addDestination } from 'api/destinations.api';
import { addLocation } from 'api/location.api';
import { addCMSMedia } from 'api/cms-media.api';
import { DESTINATION_MEDIA } from 'constants/media-sizes.constants';
import { routes } from 'routes';

export function AddDestination() {
    const history = useHistory();
    const [
        destinationToAdd,
        setDestinationToAdd,
    ] = useState<EditableDestination>({
        cms_media: null,
    });

    const [isAdding, setIsAdding] = useState(false);

    const onAdd = async () => {
        if (!isDestinationCorrect(destinationToAdd)) {
            console.error('Destination is not correct');
            return;
        }
        setIsAdding(true);
        try {
            if (!destinationToAdd.location || !destinationToAdd.area) {
                throw new Error('Location or area is not set');
            }
            const addedLocation = await addLocation(destinationToAdd.location);
            const newLocationId = addedLocation.id;
            if (!newLocationId) {
                throw new Error('Location is not added');
            }
            const addedMedia = destinationToAdd.cms_media
                ? await addCMSMedia(
                      destinationToAdd.cms_media,
                      DESTINATION_MEDIA,
                  )
                : null;
            await addDestination({
                cms_media_id: addedMedia?.id ?? undefined,
                location_id: newLocationId,
                area: destinationToAdd.area,
            });
            notification.success({
                message: 'The destination was added successfully',
            });
            history.push(routes.destinations.route);
        } catch (error) {
            notification.error({
                message: 'Something went wrong. Please, try again later',
            });
        } finally {
            setIsAdding(false);
        }
    };

    return (
        <div className="full-page">
            <PageHeader
                title="Add destination"
                ghost={false}
                onBack={() => history.push('/destinations')}
            />
            <DestinationEditor
                value={destinationToAdd}
                onChange={setDestinationToAdd}
            />
            <div className="full-page-footer">
                <div className="grow-full-flex"></div>
                <Link to="/destinations">
                    <Button size="large">Cancel</Button>
                </Link>
                <Button
                    size="large"
                    type="primary"
                    disabled={!isDestinationCorrect(destinationToAdd)}
                    onClick={onAdd}
                    loading={isAdding}
                >
                    Save changes
                </Button>
            </div>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Alert, notification } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { DestinationEditor } from 'components/DestinationEditor';
import { patchDestination, useDestination } from 'api/destinations.api';
import Loading from 'components/Loading';
import {
    areDestinationsEqual,
    isDestinationCorrect,
} from 'utils/destination-utils';
import { addLocation } from 'api/location.api';
import { addCMSMediaIfChanged } from 'api/cms-media.api';
import { DESTINATION_MEDIA } from 'constants/media-sizes.constants';
import { isSameObject } from 'utils/compare';
import { routes } from 'routes';

export function EditDestination() {
    const history = useHistory();
    const { id } = useParams<{ id: string | undefined }>();
    const { destination, error, isLoading } = useDestination(id);
    const [isPatching, setIsPatching] = useState(false);
    const [
        destinationToEdit,
        setDestinationToEdit,
    ] = useState<EditableDestination>();

    useEffect(() => {
        if (destination) {
            setDestinationToEdit(destination);
        }
    }, [destination]);

    const onPatch = async () => {
        setIsPatching(true);
        try {
            if (!destinationToEdit?.location || !destination?.location) {
                throw new Error('Destination is not ready');
            }
            const newLocation = await addLocationIfChanged(
                destinationToEdit.location,
                destination.location,
            );
            const newMedia = await addCMSMediaIfChanged(
                destinationToEdit.cms_media,
                destination?.cms_media,
                DESTINATION_MEDIA,
            );
            if (!newLocation.id || !destinationToEdit.area) {
                throw new Error('Location or media is not set');
            }
            await patchDestination({
                id: destination.id,
                location_id: newLocation.id,
                cms_media_id: newMedia?.id ?? null,
                area: destinationToEdit.area,
            });
            notification.success({
                message: 'The destination was edited successfully',
            });
            history.push(routes.destinations.route);
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Error patching the destination',
            });
        } finally {
            setIsPatching(false);
        }
    };

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return (
            <Alert
                message="Error getting info about the destination"
                description={error.message}
                type="error"
            />
        );
    }

    if (!destinationToEdit) {
        return <></>;
    }

    return (
        <div className="full-page">
            <PageHeader
                title="Edit destination"
                ghost={false}
                onBack={() => history.push('/destinations')}
            />
            <DestinationEditor
                value={destinationToEdit}
                onChange={setDestinationToEdit}
            />
            <div className="full-page-footer">
                <div className="grow-full-flex"></div>
                <Link to="/destinations">
                    <Button size="large">Cancel</Button>
                </Link>
                <Button
                    size="large"
                    type="primary"
                    disabled={
                        !isDestinationCorrect(destinationToEdit) ||
                        !destination ||
                        areDestinationsEqual(destinationToEdit, destination)
                    }
                    onClick={onPatch}
                    loading={isPatching}
                >
                    Save changes
                </Button>
            </div>
        </div>
    );
}

async function addLocationIfChanged(
    newLocation: PSLocation,
    oldLocation: PSLocation,
): Promise<PSLocation> {
    if (isSameObject(newLocation, oldLocation)) {
        return newLocation;
    }
    return addLocation(newLocation);
}

import React, { useRef, useMemo } from 'react';
import { Card, Input } from 'antd';
import { getLocationFromSpot } from 'utils/ps-spot-utils';
import { MediaSelector } from 'components/MediaSelector';
import { SPOT_MEDIA_RATIO } from 'constants/media-sizes.constants';
import SourcePhotos from './SourcePhotos';
import CustomPhotos from './CustomPhotos';
import './style.css';

const { TextArea } = Input;
/**
 *
 * @param {Object} props
 * @param {CollectionSpot} props.collectionSpot
 * @param {Guide} props.guide
 * @param {(patch: Object.<string, any>) => void} props.onPatchField
 * @param {boolean} props.visible
 */
export default function CollectionSpotEditorPhotos({
    collectionSpot,
    guide,
    onPatchField,
    visible,
}) {
    const parentRef = useRef(null);
    const spot = collectionSpot?.spot;
    const spotLocation = useMemo(() => getLocationFromSpot(spot), [spot]);
    return (
        <div className="collection-spot-editor__photos" ref={parentRef}>
            <div className="collection-spot-editor__photos-content">
                <div className="line">
                    <Card
                        title="Featured photo"
                        bodyStyle={{ padding: 0 }}
                        className="collection-spot-editor__photos-featured"
                    >
                        {visible && (
                            <MediaSelector
                                location={spotLocation}
                                collectionSpot={collectionSpot}
                                spot={spot}
                                guideId={collectionSpot.collection.guide_id}
                                includeVideos={false}
                                onSelect={(media) =>
                                    onPatchField({
                                        featured_photo: media,
                                    })
                                }
                                onDelete={() =>
                                    onPatchField({
                                        featured_photo: null,
                                    })
                                }
                                media={collectionSpot.featured_photo}
                                ratio={SPOT_MEDIA_RATIO}
                            />
                        )}
                    </Card>
                    <div className="grow-full-flex ml-m">
                        <Card title="Editor's notes">
                            <TextArea
                                className="form-field__textarea"
                                autoSize={{ minRows: 1, maxRows: 20 }}
                                value={
                                    collectionSpot.editor_notes?.photos || ''
                                }
                                onChange={(e) => {
                                    const prevNotes =
                                        collectionSpot.editor_notes || {};
                                    onPatchField({
                                        editor_notes: {
                                            ...prevNotes,
                                            photos: e.target.value,
                                        },
                                    });
                                }}
                                size="large"
                                placeholder="Share your thoughts"
                            />
                        </Card>
                    </div>
                </div>

                <CustomPhotos
                    collectionSpot={collectionSpot}
                    onChange={(newCustomPhotos) => {
                        onPatchField({
                            collection_spot_media: newCustomPhotos,
                        });
                    }}
                />

                <SourcePhotos
                    parentRef={parentRef}
                    guide={guide}
                    collectionSpot={collectionSpot}
                />
            </div>
        </div>
    );
}

import React, { useState } from 'react';
import { Button, Modal, Popover } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import MediaSelectorPreview from './MediaSelectorPreview';
import { MediaSelectorModal } from './MediaSelectorModal';
import MediaCropperModal from './MediaCropperModal';
import { isImage, isVideo } from 'constants/media-types.constants';
import {
    getMediaItemWithClosestSize,
    getMediaTypeText,
} from 'utils/media-utils';
import './style.css';
import type { MediaSource } from './MediaSelectorModal/MediaSelectorSourceSelector';

type ModalState = 'HIDDEN' | 'CHOOSE_MEDIA' | 'CROP_MEDIA';

interface CropInfo {
    aspect: number;
}

interface MediaSelectorProps {
    ratio: number;
    location?: PSLocation | null;
    spot?: PSSpot_v15_Minimal | null;
    collectionSpot?: CollectionSpot | null;
    media: CMSMedia | null;
    guideId?: Id;
    includeVideos?: boolean;
    onSelect: (media: CMSMedia & { $$cropInfo?: CropInfo }) => void;
    onDelete?: () => void;
    /** If true a popover will be shown when hovering over the preview */
    showPreviewPopover?: boolean;
    disallowedSources?: MediaSource[];
    showCropperOnEdit?: boolean;
    initialMediaSource?: MediaSource;
}

export function MediaSelector({
    ratio,
    location,
    spot,
    collectionSpot,
    media,
    guideId,
    includeVideos = false,
    onSelect,
    onDelete,
    disallowedSources = [],
    showPreviewPopover = false,
    showCropperOnEdit = true,
    initialMediaSource,
}: MediaSelectorProps) {
    const [selectedMedia, setSelectedMedia] = useState<CMSMedia | null>(media);
    const [modalState, setModalState] = useState<ModalState>('HIDDEN');
    const [crop, setCrop] = useState<CropInfo>({ aspect: ratio });

    const closeModal = () => {
        // Reset state
        setModalState('HIDDEN');
        setCrop({ aspect: ratio });
    };

    const onOKClicked = () => {
        if (!selectedMedia) {
            return;
        }

        if (isImage(selectedMedia) && modalState === 'CHOOSE_MEDIA') {
            setModalState('CROP_MEDIA');
            return;
        }

        if (isVideo(selectedMedia) || modalState === 'CROP_MEDIA') {
            onSelect({
                ...selectedMedia,
                $$cropInfo: crop,
            });
            closeModal();
        }
    };

    return (
        <>
            <div className="media-selector">
                <Popover
                    content={
                        media &&
                        showPreviewPopover &&
                        modalState === 'HIDDEN' ? (
                            <div>
                                <img
                                    src={
                                        media
                                            ? getMediaItemWithClosestSize(
                                                  media,
                                                  1000,
                                              )?.path
                                            : undefined
                                    }
                                    className="media-selector-preview__popover"
                                />
                            </div>
                        ) : null
                    }
                >
                    <div>
                        <MediaSelectorPreview
                            ratio={ratio}
                            media={media}
                            onEditClick={() => {
                                if (showCropperOnEdit && media) {
                                    setModalState('CROP_MEDIA');
                                } else {
                                    setModalState('CHOOSE_MEDIA');
                                }
                            }}
                            onDeleteClick={onDelete}
                            disabled={!location}
                            disabledText="Please select a location first"
                        />
                    </div>
                </Popover>
                <Modal
                    className={classNames(
                        'full-screen-modal',
                        'media-selector__modal',
                        {
                            'has-back-button': modalState === 'CROP_MEDIA',
                        },
                    )}
                    title={
                        <MediaSelectorModalTitle
                            modalState={modalState}
                            onBack={() => setModalState('CHOOSE_MEDIA')}
                            onCloseModal={closeModal}
                            selectedMedia={selectedMedia}
                            onOKClicked={onOKClicked}
                        />
                    }
                    open={modalState !== 'HIDDEN'}
                    onOk={closeModal}
                    onCancel={closeModal}
                    closable={false}
                    footer={null}
                    destroyOnClose
                >
                    {/* We just hide / show the media selector part of the modal so
                        if we come back to it after cropping we don't need to scroll all the way down again */}
                    <MediaSelectorModal
                        shouldShow={modalState === 'CHOOSE_MEDIA'}
                        includeVideos={includeVideos}
                        selectedMedia={selectedMedia}
                        guideId={guideId}
                        onSelect={(newSelectedMedia) =>
                            setSelectedMedia(newSelectedMedia)
                        }
                        location={location ?? null}
                        spot={spot ?? null}
                        collectionSpot={collectionSpot ?? null}
                        disallowedSources={disallowedSources}
                        initialMediaSource={initialMediaSource}
                    />
                    {modalState === 'CROP_MEDIA' && selectedMedia && (
                        <MediaCropperModal
                            selectedMedia={selectedMedia}
                            ratio={ratio}
                            crop={crop}
                            onCropChange={({ crop, media }) => {
                                setCrop(crop);
                                setSelectedMedia(media);
                            }}
                            onError={() => {
                                setModalState('CHOOSE_MEDIA');
                            }}
                            spot={spot ?? null}
                        />
                    )}
                </Modal>
            </div>
        </>
    );
}

interface MediaSelectorModalTitleProps {
    modalState: ModalState;
    onBack: () => void;
    onCloseModal: () => void;
    selectedMedia: CMSMedia | null;
    onOKClicked: () => void;
}

function MediaSelectorModalTitle({
    modalState,
    onBack,
    onCloseModal,
    selectedMedia,
    onOKClicked,
}: MediaSelectorModalTitleProps) {
    switch (modalState) {
        case 'CHOOSE_MEDIA':
            return (
                <div className="line-center">
                    <span>Media manager</span>
                    <div className="grow-full-flex" />
                    <Button key="back" onClick={onCloseModal}>
                        Cancel
                    </Button>
                    <Button
                        className="ml-s"
                        key="submit"
                        type="primary"
                        disabled={!selectedMedia}
                        onClick={onOKClicked}
                    >
                        Select {getMediaTypeText(selectedMedia)}
                    </Button>
                </div>
            );
        case 'CROP_MEDIA':
            return (
                <div className="line-center">
                    <div className="grow-full-flex">
                        <Button
                            type="link"
                            icon={<ArrowLeftOutlined />}
                            style={{ paddingLeft: 0 }}
                            onClick={onBack}
                        >
                            Media overview
                        </Button>
                        <div>Crop image</div>
                    </div>
                    <Button key="back" onClick={onCloseModal}>
                        Cancel
                    </Button>
                    <Button
                        className="ml-s"
                        key="submit"
                        type="primary"
                        disabled={!selectedMedia}
                        onClick={onOKClicked}
                    >
                        Apply crop
                    </Button>
                </div>
            );
        default:
            return null;
    }
}

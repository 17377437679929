import React from 'react';
import { MediaSelector } from 'components/MediaSelector';
import {
    DESTINATION_MEDIA,
    DESTINATION_MEDIA_RATIO,
} from 'constants/media-sizes.constants';
import { addCMSMedia } from 'api/cms-media.api';
import { patchDestination } from 'api/destinations.api';
import styles from './style.module.css';
import { notification } from 'antd';

interface DestinationImageRowProps {
    destination: Destination;
    onUpdate: () => void;
}

export function DestinationImageRow({
    destination,
    onUpdate,
}: DestinationImageRowProps) {
    return (
        <div className={styles.image}>
            <MediaSelector
                initialMediaSource="UNSPLASH"
                disallowedSources={['SHUTTERSTOCK', 'FAVORITES']}
                location={destination.location}
                includeVideos={false}
                onSelect={async (media) => {
                    try {
                        if (!destination.location.id) {
                            throw new Error('Location is not set');
                        }
                        const newMedia = await addCMSMedia(
                            media,
                            DESTINATION_MEDIA,
                        );
                        if (!newMedia.id) {
                            throw new Error('Media is not added');
                        }
                        await patchDestination({
                            id: destination.id,
                            cms_media_id: newMedia.id,
                            area: destination.area,
                            location_id: destination.location.id,
                        });
                        onUpdate();
                    } catch (error) {
                        notification.error({
                            message: 'Error updating destination image',
                            description:
                                error instanceof Error
                                    ? error.message
                                    : 'Unknown error occurred',
                        });
                    }
                }}
                media={destination.cms_media}
                ratio={DESTINATION_MEDIA_RATIO}
                showPreviewPopover={true}
                showCropperOnEdit={false}
            />
        </div>
    );
}

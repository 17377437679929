import { useState, useEffect } from 'react';

interface UseInfiniteScrollGetResult<T> {
    /** True while the get is being done */
    isLoading: boolean;
    /** Callback to get new pages for the current list */
    getNewPage: (page: number) => void;
    /** When false, we have reached the end of the "infinite" list */
    hasMoreResults: boolean;
    /** List of all the results so far in the current list */
    results: T[];
    /** Error message from last get, if any */
    errorLoadingMessage?: string;
}

type ApiGetter<T> = (
    page: number,
) => Promise<{
    hasMore: boolean;
    currentPage: number;
    results: T[];
}>;
/**
 * Hook that automatically gets infinite scroll data, using a provided getter. When the apiGetter function changes, all
 * the state will be reset
 */
export function useInfiniteScrollGet<T>(
    apiGetter: ApiGetter<T>,
): UseInfiniteScrollGetResult<T> {
    const [hasMoreResults, setHasMoreResults] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [errorLoadingMessage, setErrorLoadingMessage] = useState<string>();
    const [results, setResults] = useState<T[]>([]);
    const [isMounted, setIsMounted] = useState(true);

    useEffect(() => {
        setErrorLoadingMessage(undefined);
        setIsLoading(false);
        setHasMoreResults(true);
        setResults([]);
    }, [apiGetter]);

    useEffect(() => {
        return () => setIsMounted(false);
    }, []);

    const getNewPage = (newPage: number) => {
        if (hasMoreResults) {
            setErrorLoadingMessage(undefined);
            setIsLoading(true);
            apiGetter(newPage)
                .then((result) => {
                    if (result.currentPage === newPage && isMounted) {
                        setResults((results: T[]) => [
                            ...results,
                            ...result.results,
                        ]);
                        setHasMoreResults(result.hasMore);
                    }
                })
                .catch((e) => {
                    if (isMounted) {
                        setErrorLoadingMessage(e.toString());
                    }
                })
                .finally(() => {
                    if (isMounted) {
                        setIsLoading(false);
                    }
                });
        }
    };

    return {
        isLoading,
        getNewPage,
        hasMoreResults,
        results,
        errorLoadingMessage,
    };
}

import React from 'react';

import styles from './style.module.css';
import { Card } from 'antd';
import FormField from 'components/FormField';
import LocationSelector from 'components/LocationSelector';
import { MediaSelector } from 'components/MediaSelector';
import { getPolygonGeometryFromBBox } from 'utils/geojson-utils';
import { DESTINATION_MEDIA_RATIO } from 'constants/media-sizes.constants';
import { DestinationAreaEditor } from './DestinationAreaEditor';

interface DestinationEditorProps {
    value: EditableDestination;
    onChange: (newValue: EditableDestination) => void;
}

export function DestinationEditor({ value, onChange }: DestinationEditorProps) {
    return (
        <div className={styles.root}>
            <Card title="General">
                <FormField label="Location">
                    <LocationSelector
                        value={value.location ?? null}
                        onChange={(newLocation) => {
                            onChange({
                                ...value,
                                location: newLocation,
                                area: JSON.stringify(
                                    getPolygonGeometryFromBBox(
                                        newLocation.boundingbox,
                                    ),
                                ),
                            });
                        }}
                    />
                </FormField>
            </Card>
            <Card title="Cover photo" bodyStyle={{ padding: 0 }}>
                <MediaSelector
                    initialMediaSource="UNSPLASH"
                    showCropperOnEdit={false}
                    disallowedSources={['SHUTTERSTOCK', 'FAVORITES']}
                    location={value.location}
                    includeVideos={false}
                    onSelect={(media) => {
                        onChange({
                            ...value,
                            cms_media: media,
                        });
                    }}
                    onDelete={() => {
                        onChange({
                            ...value,
                            cms_media: null,
                        });
                    }}
                    media={value.cms_media}
                    ratio={DESTINATION_MEDIA_RATIO}
                />
            </Card>
            {value.area ? (
                <Card title="Map" className={styles.mapCard}>
                    <DestinationAreaEditor
                        value={JSON.parse(value.area)}
                        onChange={(newArea) => {
                            onChange({
                                ...value,
                                area: JSON.stringify(newArea),
                            });
                        }}
                    />
                </Card>
            ) : null}
        </div>
    );
}

/**
 * Converts PSLocationin old format to new (v2) format
 *
 * @param {PSLocation} location
 * @returns {PSLocation2}
 */
export const location1toLocation2 = (location) => {
    return {
        source: null,
        lat: location.lat,
        lon: location.lon,
        locality: location.name,
        administrative_area: location.fullDetail,
        country: location.detail,
        country_code: location.countryCode,
        single_line: null,
    };
};

/**
 *
 * @param {AutocompleteLocation} scl
 * @param {number} id
 * @returns {PSLocationWithBB}
 */
export const sharedCodebaseLocationToPSLocationWithBB = (scl, id) => {
    return {
        id,
        name: scl.locality,
        lat: scl.lat,
        lon: scl.lon,
        detail: scl.country,
        fullDetail: scl.presentation.fullResult,
        countryCode: scl.countryCode,
        boundingbox: getGeoJSONBBox(scl.boundingBox),
    };
};
/**
 * Gets a correct GeoJSON BBox from SharedCodebase BBox
 *
 * @param {BoundingBox} bbox
 * @returns {GeoJSON.BBox} [minlon, minlat, maxlon, maxlat]
 */
function getGeoJSONBBox(bbox) {
    if (!bbox) {
        return null;
    }
    const minlat = bbox.northEast.latitude;
    const maxlat = bbox.southWest.latitude;
    const minlon = bbox.southWest.longitude;
    const maxlon = bbox.northEast.longitude;
    return [minlon, minlat, maxlon, maxlat];
}

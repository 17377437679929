interface ObjectWithIdAndEditedInfo {
    id: string | number;

    last_edited_date?: Date;
    last_edited_by?: { first_name: string };
}

/**
 * Augments the objects of an array by adding a key equal to their id and a lastEdited field containing last_edited_by and last_edited_date info
 */
export function getDataSourceFromArray<T extends ObjectWithIdAndEditedInfo>(
    array: T[],
): T[] {
    return array.map((rawItem) => {
        const lastEdited = getLastEditedText(rawItem);
        return { ...rawItem, key: rawItem.id, lastEdited };
    });
}

function getLastEditedText(rawItem: ObjectWithIdAndEditedInfo) {
    if (!rawItem.last_edited_date || !rawItem.last_edited_by) {
        return '';
    }
    return `${getDateString(rawItem.last_edited_date)} by ${
        rawItem.last_edited_by.first_name
    }`;
}

function getDateString(date: Date) {
    return `${date.toLocaleString(undefined, {
        dateStyle: 'short',
    })} at ${date.toLocaleTimeString(undefined, {
        timeStyle: 'short',
    })}`;
}

export function objectContainsText(
    obj: Record<string, string>,
    text: string,
    /** Name of fields we should check */
    fieldsToCheck: string[],
    /** Function to use to filter, if not specified we will use fieldsToCheck param */
    filterFn?: (obj: Record<string, string>, text: string) => boolean,
): boolean {
    if (!text) {
        return true;
    }

    // If provided, we use filterFn
    if (filterFn) {
        return filterFn(obj, text);
    }

    // If no filterFn we check fieldsToCheck
    const lowerCaseFilter = text.toLowerCase();
    for (const fieldName of fieldsToCheck) {
        const possibleText = obj[fieldName];
        const text = (possibleText || '').toLowerCase();
        if (text.includes(lowerCaseFilter)) {
            return true;
        }
    }
    return false;
}

import React, { useMemo } from 'react';
import MediaItemContent from '../MediaItemContent';
import { getMediaItemWithClosestSize } from 'utils/media-utils';

/**
 *
 * @param {Object} props
 * @param {CMSMedia} props.media
 * @param {Id} [props.guideId]
 * @param {(media: CMSMedia) => void} props.onSelect
 * @param {boolean} props.isSelected
 */
export default function UploadedPhotoMediaItem({
    media,
    guideId,
    onSelect,
    isSelected,
}) {
    const pathInfo = useMemo(() => getMediaItemWithClosestSize(media, 400), [
        media,
    ]);

    return (
        <MediaItemContent
            media={media}
            guideId={guideId}
            onSelect={() => onSelect(media)}
            isSelected={isSelected}
            mediaType="PSMedia"
            isLoading={false}
            canBeFavorite={false}
            imgSrc={pathInfo.path}
        />
    );
}

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IMAGE, VIDEO } from 'constants/media-types.constants';
import { BASE_URL } from 'constants/env';
import styles from './MediaDiscoveryItem.module.css';
import { useMediaUser } from 'utils/use-media-user';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import dayjs from 'dayjs';

interface MediaDiscoveryItemProps {
    media: PSMedia;
}

export function MediaDiscoveryItem({ media }: MediaDiscoveryItemProps) {
    const [isHovering, setIsHovering] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const preview = useMemo(() => {
        if (media.type === IMAGE || !isHovering) {
            return (
                <img
                    className={styles.preview}
                    src={media.large_thumbnail_path}
                    loading="lazy"
                />
            );
        }

        if (media.type === VIDEO) {
            return (
                <video
                    ref={videoRef}
                    className={styles.preview}
                    autoPlay
                    muted
                    controls
                    src={media.cdn_path}
                />
            );
        }

        return <div className={styles.preview}>Wrong media type</div>;
    }, [media, isHovering]);
    // isHovered is used to get the user for the media only once the user has hovered over the media
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if (isHovering) {
            videoRef.current?.play();
        } else {
            videoRef.current?.pause();
        }
    }, [isHovering]);
    const { user } = useMediaUser(isHovered ? media : null);

    return (
        <div
            className={styles.root}
            onMouseEnter={() => {
                setIsHovered(true);
                setIsHovering(true);
            }}
            onMouseLeave={() => {
                setIsHovering(false);
            }}
        >
            {preview}
            <div className={styles.tools}>
                {user ? (
                    <div className={styles.tools__userInfo}>
                        <div>
                            {media.type === IMAGE ? 'Photo' : 'Video'} from{' '}
                            <a
                                href={`${BASE_URL}/${user.username}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {user.username}
                            </a>
                        </div>
                        <a
                            href={`${BASE_URL}/${user.username}/${media.step?.trip_id}-unkown-name/${media.step?.id}-unknown-name`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            View step
                        </a>
                        {media.step ? (
                            <div>
                                {dayjs(media.step.start_time).format(
                                    'DD MMM YYYY',
                                )}
                            </div>
                        ) : null}
                        {user.email ? (
                            <a
                                href={
                                    `mailto:${
                                        user.email
                                    }?subject=Can we use your ${
                                        media.type === IMAGE ? 'photo' : 'video'
                                    }?&body=This is only a test!` // Body and subject are yet to be determined when editors start using this feature
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                {user.email}
                            </a>
                        ) : null}
                    </div>
                ) : null}
                <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    size="small"
                    target="_blank"
                    href={
                        media.type === IMAGE
                            ? media.large_thumbnail_path
                            : media.cdn_path
                    }
                />
            </div>
        </div>
    );
}

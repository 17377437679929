export function isDestinationCorrect(destination: EditableDestination) {
    return (
        !!destination.location && !!destination.area && !!destination.cms_media
    );
}

export function areDestinationsEqual(
    destination1: EditableDestination,
    destination2: EditableDestination,
) {
    return (
        destination1.location?.id === destination2.location?.id &&
        destination1.area === destination2.area &&
        destination1.cms_media?.id === destination2.cms_media?.id &&
        JSON.stringify(destination1.cms_media?.paths) ===
            JSON.stringify(destination2.cms_media?.paths)
    );
}

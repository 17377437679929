import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { PlusOutlined } from '@ant-design/icons';
import { useDebounce } from '@react-hook/debounce';
import { GenericOverviewTable } from 'components/GenericOverviewTable';
import type { ColumnType, SortOrder } from 'antd/lib/table/interface';
import { getAllDestinations } from 'api/destinations.api';
import { DestinationImageRow } from './DestinationImageRow';

import styles from './style.module.css';
import { useLocalStorage } from 'react-use';

const { Search } = Input;

export function Destinations() {
    // Ideally we could use useSearchParams here, but it is not available in this version of react-router, so we just save the last used filter in local storage
    const [lastUsedFilter, setLastUsedFilter] = useLocalStorage(
        'destinations-filter',
        '',
    );
    const [filter, setFilter] = useDebounce(lastUsedFilter ?? '', 300);
    useEffect(() => {
        setLastUsedFilter(filter);
    }, [filter, setLastUsedFilter]);
    const [sortColumn, setSortColumn] = useState<string>();
    const [sortOrder, setSortOrder] = useState<string>();
    const filterWithEnoughCharacters = filter.length > 2 ? filter : '';
    const apiGetter = useCallback(
        (page: number) => {
            return getAllDestinations(
                filterWithEnoughCharacters,
                sortColumn,
                sortOrder,
                page,
            );
        },
        [filterWithEnoughCharacters, sortColumn, sortOrder],
    );
    const [apiGetterKey, setApiGetterKey] = useState(0);
    const [columns] = useState<ColumnType<Destination>[]>([
        {
            title: 'Image',
            dataIndex: 'image_url',
            key: 'cms_media_id',
            render: (_text: unknown, destination: Destination) => (
                <DestinationImageRow
                    destination={destination}
                    onUpdate={() => setApiGetterKey((old) => old + 1)} // Triggering the update of the
                />
            ),
            sorter: true,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'location_name',
            render: (_text: unknown, destination: Destination) => (
                <Link to={`/destinations/${destination.id}`}>
                    {destination.location.name}
                </Link>
            ),
            sorter: true,
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'location_detail',
            render: (_text: unknown, destination: Destination) => (
                <Link to={`/destinations/${destination.id}`}>
                    {destination.location.detail}
                </Link>
            ),
            sorter: true,
        },
    ]);

    return (
        <div className="full-page">
            <PageHeader
                ghost={false}
                title="Destinations"
                extra={
                    <Link
                        to="/destinations/add"
                        className="full-page-header-tools-add-button"
                    >
                        <Button
                            type="primary"
                            size="large"
                            icon={<PlusOutlined />}
                        >
                            Add new destination
                        </Button>
                    </Link>
                }
            >
                <Search
                    className="full-page-header-tools-filter"
                    placeholder="Search for destinations"
                    size="large"
                    defaultValue={filter}
                    onChange={(e) => setFilter(e.target.value)}
                />
            </PageHeader>
            <div className="full-page-content">
                <div className="max-content-width mb-m">
                    <GenericOverviewTable
                        key={apiGetterKey}
                        className={styles.table}
                        columns={columns}
                        apiGetter={apiGetter}
                        emptyTableMessage={
                            filter
                                ? 'No destinations found with this text.'
                                : 'Sorry no destinations yet.'
                        }
                        sortCallback={(column, order) => {
                            setSortColumn(column ? `${column}` : undefined);
                            setSortOrder(getSortOrder(order ?? undefined));
                        }}
                    ></GenericOverviewTable>
                </div>
            </div>
        </div>
    );
}

function getSortOrder(order: SortOrder | undefined) {
    return order === 'ascend' ? 'asc' : 'desc';
}

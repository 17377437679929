export const GUIDE_COVER_RATIO = 16 / 9;

/** @type {MediaSize[]} */
export const GUIDE_COVER = [
    {
        maxWidth: 4000,
        maxHeight: Math.round(4000 / GUIDE_COVER_RATIO),
        quality: 0.9,
    },
    {
        maxWidth: 3000,
        maxHeight: Math.round(3000 / GUIDE_COVER_RATIO),
        quality: 0.8,
    },
    {
        maxWidth: 1500,
        maxHeight: Math.round(1500 / GUIDE_COVER_RATIO),
        quality: 0.8,
    },
    {
        maxWidth: 1000,
        maxHeight: Math.round(1000 / GUIDE_COVER_RATIO),
        quality: 0.9,
    },
    {
        maxWidth: 400,
        maxHeight: Math.round(400 / GUIDE_COVER_RATIO),
        quality: 0.9,
    },
];

export const STORY_COVER_RATIO = 9 / 16;

/** @type {MediaSize[]} */
export const STORY_COVER = [
    {
        maxWidth: Math.round(4000 * STORY_COVER_RATIO),
        maxHeight: 4000,
        quality: 0.9,
    },
    {
        maxWidth: Math.round(2000 * STORY_COVER_RATIO),
        maxHeight: 2000,
        quality: 0.9,
    },
    {
        maxWidth: Math.round(1000 * STORY_COVER_RATIO),
        maxHeight: 1000,
        quality: 0.9,
    },
    {
        maxWidth: Math.round(400 * STORY_COVER_RATIO),
        maxHeight: 400,
        quality: 0.9,
    },
];

export const HIGHLIGHT_COVER_RATIO = 33 / 43;

/** @type {MediaSize[]} */
export const HIGHLIGHT_COVER = [
    {
        maxWidth: Math.round(4000 * HIGHLIGHT_COVER_RATIO),
        maxHeight: 4000,
        quality: 0.9,
    },
    {
        maxWidth: Math.round(2000 * HIGHLIGHT_COVER_RATIO),
        maxHeight: 2000,
        quality: 0.9,
    },
    {
        maxWidth: Math.round(1000 * HIGHLIGHT_COVER_RATIO),
        maxHeight: 1000,
        quality: 0.9,
    },
    {
        maxWidth: Math.round(400 * HIGHLIGHT_COVER_RATIO),
        maxHeight: 400,
        quality: 0.9,
    },
];

export const SPOT_MEDIA_RATIO = 16 / 9;

/** @type {MediaSize[]} */
export const SPOT_MEDIA = [
    {
        maxWidth: 4000,
        maxHeight: Math.round(4000 / SPOT_MEDIA_RATIO),
        quality: 0.9,
    },
    {
        maxWidth: 1000,
        maxHeight: Math.round(1000 / SPOT_MEDIA_RATIO),
        quality: 0.9,
    },
    {
        maxWidth: 400,
        maxHeight: Math.round(400 / SPOT_MEDIA_RATIO),
        quality: 0.9,
    },
];

/** @type {MediaSize[]} */
// Media shown in the spot detail part, they don't need to big hi res
export const SPOT_DETAIL_MEDIA = [
    {
        maxWidth: 1000,
        maxHeight: 1000,
        quality: 0.9,
    },
    {
        maxWidth: 400,
        maxHeight: 400,
        quality: 0.9,
    },
];

export const DESTINATION_MEDIA_RATIO = 16 / 9;

/** @type {MediaSize[]} */
/*
 * Media shown in the location detail part, they don't need to big hi res
 * Why not a square?: Matt: "The square crop is very problematic as the landscape view of this crop (seen in large when opening the planned step page)
 * will only show the top third of the square crop (this will result in poor images for most destinations in this view). Whereas, the square thumbnail
 * taken from a landscape crop will be much less problematic (and less obvious given the size of the thumbnail). The current Guide images that are sourced
 * for this (Cover photo) are landscape - the same crop would suffice here."  (https://polarsteps.slack.com/archives/C051DBAF63X/p1736834110593479)
 */
export const DESTINATION_MEDIA = [
    {
        maxWidth: 1000,
        maxHeight: Math.round(1000 / DESTINATION_MEDIA_RATIO),
        quality: 0.9,
    },
    {
        maxWidth: 400,
        maxHeight: Math.round(400 / DESTINATION_MEDIA_RATIO),
        quality: 0.9,
    },
];

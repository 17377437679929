import {
    CalendarOutlined,
    CheckOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import {
    Button,
    Card,
    DatePicker,
    Input,
    Modal,
    Switch,
    Typography,
    notification,
} from 'antd';
import { addCMSMedia } from 'api/cms-media.api';
import { getNonBoughtStockImages } from 'api/shutterstock.api';
import AuthorSelector from 'components/AuthorSelector';
import FormField from 'components/FormField';
import GuideTypeSelector from 'components/GuideTypeSelector';
import LocationSelector from 'components/LocationSelector';
import { MediaSelector } from 'components/MediaSelector';
import PublishStatusSelector from 'components/PublishStatusSelector';
import { PurchaseStockPhotoWarningModal } from 'components/StockPhotoWarning';
import {
    GUIDE_CUSTOM_LOCATION_MAX_LENGTH,
    GUIDE_DESCRIPTION_MAX_LENGTH,
    GUIDE_TAGLINE_MAX_LENGTH,
} from 'constants/fields-limits';
import {
    GUIDE_COVER,
    GUIDE_COVER_RATIO,
} from 'constants/media-sizes.constants';
import { format, parseISO } from 'date-fns';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import {
    patchGuide,
    patchGuideField,
    patchGuideLocation,
    saveNewGuide,
} from 'store/guide-editor/actions';
import {
    getCurrentGuide,
    getOriginalGuide,
    hasGuideChanged,
    hasGuideChangedLocation,
    isAddingGuide,
    isGuideCorrect,
    isPatchingGuide,
    isSavingNewGuide,
} from 'store/guide-editor/selectors';
import { isSameExternalMedia } from 'utils/media-utils';
import { getNonBoughtMediaFromList } from 'utils/stock-images-utils';
import GuideRankingSelector from '../GuideRankingSelector';
import GuidePurchaseWarningModal from './GuidePurchaseWarningModal';
import GuideQuickFacts from './GuideQuickFacts';

const { confirm } = Modal;
const { TextArea } = Input;
const { Text } = Typography;
/**
 *
 * @param {Object} props
 * @param {() => void} props.onClickDiscard
 */
function GuideEditorBasicInfo({ onClickDiscard }) {
    const dispatch = useDispatch();
    const guide = useSelector(getCurrentGuide);
    const originalGuide = useSelector(getOriginalGuide);
    const savingNewGuide = useSelector(isSavingNewGuide);
    const patchingGuide = useSelector(isPatchingGuide);
    const correctGuide = useSelector(isGuideCorrect);
    const changedGuide = useSelector(hasGuideChanged);
    const addingGuide = useSelector(isAddingGuide);
    const locationHasChanged = useSelector(hasGuideChangedLocation);
    const history = useHistory();
    const [loadingStockPhotos, setLoadingStockPhotos] = useState(false);
    const [isPurchaseWarningShown, setIsPurchaseWarningShown] = useState(false);
    const [isAddingPhotos, setIsAddingPhotos] = useState(false);
    const [photosToPurchase, setPhotosToPurchase] = useState(0);
    const [
        isAddingStockPhotoWarningShown,
        setIsAddingStockPhotoWarningShown,
    ] = useState(false);

    const publishedAt = useMemo(() => {
        if (!guide?.published_at) {
            return '';
        }
        return format(parseISO(guide.published_at), 'd MMM y HH:mm');
    }, [guide?.published_at]);

    /**
     * Will show a warning if location has changed. If user selects to proceed (or location was not changed) it will resolve to true, otherwsie to false.
     *
     * @returns {Promise<boolean>}
     */
    const showWarningIfLocationChanged = () => {
        if (!locationHasChanged) {
            return Promise.resolve(true);
        }
        return new Promise((resolve) => {
            confirm({
                title: 'Change location',
                content:
                    'You are changing the location of this guide. The guide will be displayed on another location. Are you sure you want to do this?',
                onOk() {
                    resolve(true);
                },
                onCancel() {
                    resolve(false);
                },
                okText: 'Change',
                cancelText: 'Cancel',
            });
        });
    };

    /**
     * Checks if the guide is being published, and should show a warning about all the stock images that will be bought.
     *
     * @returns {Promise<{shouldShow: boolean, numberOfPhotos: number}>}
     */
    const shouldShowPublishingWarning = useCallback(async () => {
        if (
            guide.publish_status.id === originalGuide?.publish_status.id ||
            guide.publish_status.name !== 'published'
        ) {
            return Promise.resolve({ shouldShow: false, numberOfPhotos: null }); // Not publishing the guide
        }
        setLoadingStockPhotos(true);
        const photos = await getNonBoughtStockImages(guide);
        setLoadingStockPhotos(false);
        return Promise.resolve({
            shouldShow: !!photos,
            numberOfPhotos: photos,
        });
    }, [guide, originalGuide, setLoadingStockPhotos]);

    /**
     * Checks if the guide is published, and the editor is adding as a cover a stock photo that will be bought.
     *
     * @returns {Promise<{boolean}>} When it returns true, the warning should be shown
     */
    const shouldShowWarningAddingStockPhoto = useCallback(async () => {
        if (originalGuide?.publish_status.name !== 'published') {
            return false;
        }

        if (
            isSameExternalMedia(guide.cover_image, originalGuide?.cover_image)
        ) {
            // Editor is not changing the photo
            return false;
        }
        const shouldShowWarning = !!(
            await getNonBoughtMediaFromList([guide.cover_image])
        ).length;
        if (!shouldShowWarning) {
            return false;
        }
        return true;
    }, [guide, originalGuide]);

    if (!guide) {
        return null;
    }

    /**
     *
     * @param {CMSMedia} cmsMedia new cover for the guide, undefined if no change, null if no cover
     */
    const dispatchSaveGuide = async (cmsMedia) => {
        try {
            await dispatch(patchGuide(cmsMedia));
            notification.success({
                message: 'Changes saved!',
            });
        } catch (e) {
            Modal.error({
                title: 'Error saving guide',
                content: e.message,
            });
        }
    };

    /**
     *
     * @param {CMSMedia} cmsMedia new media to assign to the guide
     */
    const saveGuide = async (cmsMedia) => {
        if (addingGuide) {
            await dispatch(saveNewGuide(cmsMedia));
            notification.success({
                message: 'The guide was added successfully',
            });
            history.push(routes['guides-overview'].route);
            return;
        }
        // Editing guide
        const canPatch = await showWarningIfLocationChanged();
        if (!canPatch) {
            return;
        }
        const shouldShowInfo = await shouldShowPublishingWarning();
        setPhotosToPurchase(shouldShowInfo.numberOfPhotos);
        if (shouldShowInfo.shouldShow) {
            setIsPurchaseWarningShown(true);
            return;
        }

        dispatchSaveGuide(cmsMedia);
    };

    const onClickSave = async () => {
        const shouldShowWarning = await shouldShowWarningAddingStockPhoto();
        if (shouldShowWarning) {
            setIsAddingStockPhotoWarningShown(true);
            return;
        }
        if (!guide.cover_image) {
            return saveGuide(null);
        }
        if (
            isSameExternalMedia(guide.cover_image, originalGuide?.cover_image)
        ) {
            // Editor is not changing the photo
            return saveGuide(undefined);
        }
        // Editor is changing the photo
        setIsAddingPhotos(true);
        const newPhoto = await addCMSMedia(guide.cover_image, GUIDE_COVER);
        setIsAddingPhotos(false);
        saveGuide(newPhoto);
    };

    return (
        <>
            <div className="guide-editor-basic-info__container">
                <div>
                    <Card title="Basic info">
                        <FormField label="Guide type">
                            <GuideTypeSelector
                                value={guide.guide_type.id}
                                onChange={(newId, newGuideType) => {
                                    dispatch(
                                        patchGuideField(
                                            'guide_type',
                                            newGuideType,
                                        ),
                                    );
                                }}
                            />
                        </FormField>

                        <FormField label="Location">
                            <LocationSelector
                                value={guide.location}
                                onChange={(newLocation) => {
                                    dispatch(patchGuideLocation(newLocation));
                                }}
                            />
                        </FormField>
                        {guide.location &&
                            !(guide.viewport_area || guide.search_area) && (
                                <div className="mb-l">
                                    There is no viewport selected for this
                                    guide, please reselect the location for it
                                    to be automatically updated.
                                </div>
                            )}
                        <FormField
                            label="Cust. location name"
                            length={guide.location_name_custom.length}
                            maxLength={GUIDE_CUSTOM_LOCATION_MAX_LENGTH}
                        >
                            <Input
                                value={guide.location_name_custom}
                                onChange={(e) =>
                                    dispatch(
                                        patchGuideField(
                                            'location_name_custom',
                                            e.target.value,
                                        ),
                                    )
                                }
                                size="large"
                                placeholder="Optional"
                            />
                        </FormField>

                        <FormField
                            label="Tagline"
                            length={guide.tagline.length}
                            maxLength={GUIDE_TAGLINE_MAX_LENGTH}
                        >
                            <Input
                                value={guide.tagline}
                                onChange={(e) =>
                                    dispatch(
                                        patchGuideField(
                                            'tagline',
                                            e.target.value,
                                        ),
                                    )
                                }
                                size="large"
                                placeholder=""
                            />
                        </FormField>

                        <FormField
                            label="Description"
                            length={(guide.description || '').length}
                            maxLength={GUIDE_DESCRIPTION_MAX_LENGTH}
                        >
                            <TextArea
                                className="form-field__textarea"
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                value={guide.description || ''}
                                onChange={(e) =>
                                    dispatch(
                                        patchGuideField(
                                            'description',
                                            e.target.value,
                                        ),
                                    )
                                }
                                size="large"
                                placeholder="Optional"
                            />
                        </FormField>

                        <FormField label="Map visibility">
                            <GuideRankingSelector
                                value={guide.guide_ranking.id}
                                onChange={(newId, newGuideRanking) => {
                                    dispatch(
                                        patchGuideField(
                                            'guide_ranking',
                                            newGuideRanking,
                                        ),
                                    );
                                }}
                            />
                        </FormField>

                        <FormField label="Status">
                            <PublishStatusSelector
                                value={guide.publish_status?.id}
                                onChange={(newId, newValue) => {
                                    dispatch(
                                        patchGuideField(
                                            'publish_status',
                                            newValue,
                                        ),
                                    );
                                }}
                            />
                        </FormField>

                        <FormField label="Editor">
                            <AuthorSelector
                                value={guide.main_editor_id}
                                onChange={(newEditorId, newEditor) => {
                                    dispatch(
                                        patchGuideField(
                                            'main_editor_id',
                                            newEditorId,
                                        ),
                                    );
                                    dispatch(
                                        patchGuideField(
                                            'main_editor',
                                            newEditor,
                                        ),
                                    );
                                }}
                            />
                        </FormField>
                        <FormField label="Orientation content only">
                            <Switch
                                checked={
                                    guide.orientation_content_only ?? false
                                }
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                onChange={(checked) =>
                                    dispatch(
                                        patchGuideField(
                                            'orientation_content_only',
                                            checked,
                                        ),
                                    )
                                }
                                defaultChecked={false}
                            />
                        </FormField>
                    </Card>
                    <div className="mb-m" />
                    <Card title="Meta info">
                        <FormField
                            label="Description"
                            length={guide.meta_description?.length}
                            maxLength={160}
                        >
                            <TextArea
                                value={guide.meta_description}
                                onChange={(e) =>
                                    dispatch(
                                        patchGuideField(
                                            'meta_description',
                                            e.target.value,
                                        ),
                                    )
                                }
                                size="large"
                                placeholder="Optional"
                            />
                        </FormField>
                    </Card>
                    <div className="mb-m" />
                    <Card title="Quick Look">
                        <GuideQuickFacts
                            value={guide.quick_facts}
                            onChange={(newQuickFacts) =>
                                dispatch(
                                    patchGuideField(
                                        'quick_facts',
                                        newQuickFacts,
                                    ),
                                )
                            }
                        />
                    </Card>
                </div>
                <div>
                    <Card title="Cover photo" bodyStyle={{ padding: 0 }}>
                        <MediaSelector
                            location={guide.location}
                            spot={null}
                            guideId={guide.id}
                            includeVideos={false}
                            onSelect={(media) =>
                                dispatch(patchGuideField('cover_image', media))
                            }
                            onDelete={() =>
                                dispatch(patchGuideField('cover_image', null))
                            }
                            media={guide.cover_image}
                            ratio={GUIDE_COVER_RATIO}
                        />
                    </Card>
                    <div className="mb-m" />
                    <Card title="First publish date">
                        <Input
                            defaultValue={publishedAt}
                            disabled={true}
                            suffix={<CalendarOutlined />}
                        />
                        <div className="mt-sm" />
                        <Text type="secondary">
                            This field is completed automatically when you
                            publish the guide for the first time. New guides
                            appear on the “New Guides” section on guides
                            discovery in the app.
                        </Text>
                    </Card>
                    <div className="mb-m" />
                    <Card title="Last reviewed">
                        <FormField>
                            {/* @ts-ignore*/}
                            <DatePicker
                                defaultValue={
                                    guide.reviewed_at &&
                                    dayjs(guide.reviewed_at, 'YYYY-MM-DD')
                                }
                                format={'DD MMM YYYY'}
                                className="form-field__datepicker"
                                onChange={(e) =>
                                    dispatch(
                                        patchGuideField(
                                            'reviewed_at',
                                            e && e.format('YYYY-MM-DD'),
                                        ),
                                    )
                                }
                            />
                        </FormField>
                        <div className="mt-sm" />
                        <Text type="secondary">
                            Changing the date in this field affects the date
                            this guide was last reviewed.
                        </Text>
                    </Card>
                    <div className="mb-m" />
                    <Card title="Editor's notes">
                        <TextArea
                            className="form-field__textarea"
                            autoSize={{ minRows: 1, maxRows: 20 }}
                            value={guide.editor_notes || ''}
                            onChange={(e) =>
                                dispatch(
                                    patchGuideField(
                                        'editor_notes',
                                        e.target.value,
                                    ),
                                )
                            }
                            size="large"
                            placeholder="Share your thoughts"
                        />
                    </Card>
                </div>
            </div>
            <div className="full-page-tabs-footer">
                <div className="max-content-width">
                    <div className="grow-full-flex"></div>
                    <Button size="large" onClick={onClickDiscard}>
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        type="primary"
                        loading={
                            savingNewGuide ||
                            patchingGuide ||
                            loadingStockPhotos ||
                            isAddingPhotos
                        }
                        onClick={onClickSave}
                        disabled={!correctGuide || !changedGuide}
                    >
                        Save changes
                    </Button>
                </div>
            </div>
            {isPurchaseWarningShown && (
                <GuidePurchaseWarningModal
                    guide={guide}
                    photosToPurchase={photosToPurchase}
                    onPurchased={() => {
                        setIsPurchaseWarningShown(false);
                        dispatchSaveGuide(undefined); // Editor is publishing the guide, we assume no new cover has been set
                    }}
                    onCancel={() => {
                        setIsPurchaseWarningShown(false);
                    }}
                />
            )}
            {isAddingStockPhotoWarningShown && (
                <PurchaseStockPhotoWarningModal
                    photosToPurchase={[guide.cover_image]}
                    onAdd={(purchasedPhotos) => {
                        setIsAddingStockPhotoWarningShown(false);
                        dispatchSaveGuide(purchasedPhotos[0]);
                    }}
                    onCancel={() => {
                        setIsAddingStockPhotoWarningShown(false);
                    }}
                    sizesToUpload={GUIDE_COVER}
                />
            )}
        </>
    );
}

export default GuideEditorBasicInfo;

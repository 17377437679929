import React from 'react';
import { Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import {
    convertSpotMediaToCMSMedia,
    isSpotMediaSelected,
} from 'utils/media-utils';
import SpotMediaItem from './SpotMediaItem';
import { useInfiniteSpotMedia } from 'utils/use-infinite-spot-media';

/**
 *
 * @param {Object} props
 * @param {CMSMedia?} props.selectedMedia
 * @param {Id} [props.guideId]
 * @param {(media: CMSMedia) => void} props.onSelect
 * @param {boolean} props.isShown
 * @param {React.ReactNode} props.sourceSelector
 * @param {PSSpot_v15 | null} props.spot
 * @returns {JSX.Element}
 */
function SpotMedia({
    selectedMedia,
    guideId,
    onSelect,
    isShown,
    sourceSelector,
    spot,
}) {
    const selectMedia = async (media) => {
        const convertedMedia = await convertSpotMediaToCMSMedia(spot, media);
        onSelect(convertedMedia);
    };
    const {
        allMedia,
        isLoading,
        getNewPage,
        hasMoreResults,
    } = useInfiniteSpotMedia(spot);

    return (
        <div style={{ display: isShown ? 'block' : 'none' }}>
            <div className="media-selector__tools">
                {sourceSelector}
                <div className="grow-full-flex"></div>
            </div>
            {!isLoading && !allMedia.length && (
                <div className="media-selector-no-results">
                    There is no media for this spot
                </div>
            )}
            <div className="media-selector-image-list-container">
                <InfiniteScroll
                    className="media-selector-image-list"
                    pageStart={0}
                    loadMore={getNewPage}
                    threshold={400}
                    hasMore={!isLoading && hasMoreResults}
                    useWindow={false}
                >
                    {allMedia.map((photo) => (
                        <SpotMediaItem
                            key={photo.external_id}
                            media={photo}
                            spot={spot}
                            guideId={guideId}
                            onSelect={() => selectMedia(photo)}
                            isSelected={isSpotMediaSelected(
                                photo,
                                selectedMedia,
                            )}
                        />
                    ))}
                </InfiniteScroll>
                {isLoading && (
                    <div className="media-selector__loading">
                        <Spin />
                    </div>
                )}
            </div>
        </div>
    );
}

export default SpotMedia;
